// Fonts
@import url('https://fonts.googleapis.com/css?family=Lato');



// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css";

@import "~bootstrap-daterangepicker/daterangepicker.css";

@import "./colors";

.daterangepicker {
    z-index: 1151 !important;
}


.summary-row {
    display: block;
    .summary-fieldname{
        font-weight: bold;
        display: inline-block;
        width: 150px;
    }

    .summary-value{
        display: inline-block;
    }
}


